<template>
  <div>
    <div class="w-100 d-flex justify-content-end">
      <CButton color="primary" @click="openCreationModal">
        {{ $t("Add news") }}
      </CButton>
    </div>
    <table-component
      :data="news"
      :columns="tableColumns"
      :headings="{ image_url: $t('Image') }"
    >
      <template #status="{ row }">
        <c-badge color="success">{{ statuses[row.status] }}</c-badge>
      </template>
      <template #label="{ row }">
        <c-badge color="success">{{ $t(row.label) }}</c-badge>
      </template>
      <template #image_url="{ row }">
        <img
          :src="row.image_url"
          :alt="row.image_url"
          style="max-height: 200px; max-width: 300px"
        />
      </template>
      <template #content="{ row }">
        <div
          v-if="row.content"
          v-html="row.content"
          v-c-tooltip="{
            content: row.content,
            html: true,
          }"
        />
      </template>
      <template #actions="{ row }">
        <div>
          <CButton
            variant="outline"
            color="primary"
            class="mr-2 mz-icon-btn sm icon-white"
            @click="deleteNews(row)"
          >
            <i class="fa fa-trash" />
          </CButton>
          <CButton
            variant="outline"
            color="primary"
            class="mz-icon-btn sm icon-white"
            @click="openCreationModal(row, false)"
          >
            <i class="fa fa-pencil" />
          </CButton>
        </div>
      </template>
    </table-component>
    <CModal :title="$t('Create news')" v-model:show="showCreationModal">
      <CInput :label="$t('Title')" v-model="editedNews.title" required />
      <MzSelect
        v-if="statusOptions.length"
        :label="$t('Status')"
        v-model="editedNews.status"
        :options="statusOptions"
        required
        class="my-2"
      />
      <CInput :label="$t('Label')" v-model="editedNews.label" required />
      <div class="d-flex flex-column my-2">
        <label class="mr-3">{{ $t("Image") }}</label>
        <ImageBankInput
          @selectedImage="(img) => (editedNews.image_url = img.uri)"
        />
      </div>
      <div class="d-flex flex-column my-2">
        <label class="mr-3">{{ $t("Content") }}*</label>
        <RaTextEditor
          required
          v-model="editedNews.content"
          class="content-editor"
        />
      </div>
      <template #footer>
        <CButton color="secondary" @click="cancelCreation">
          {{ $t("Cancel") }}
        </CButton>
        <CButton
          :disabled="!mandatoryFilled"
          color="primary"
          @click="editedNews.isNew ? createNews() : updateNews()"
        >
          {{ $t("Save") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { NewsService } from "@/services/news";

export default {
  name: "News",
  data() {
    return {
      tableColumns: [
        "title",
        "status",
        "label",
        "image_url",
        "content",
        "actions",
      ],
      news: [],
      statuses: [],
      showCreationModal: false,
      defaultEditedNews: {
        title: "",
        status: "",
        image_url: "",
        content: "",
      },
      editedNews: {},
    };
  },
  computed: {
    statusOptions() {
      return Object.entries(this.statuses).map(([value, name]) => ({
        label: name,
        value: parseInt(value),
      }));
    },
    mandatoryFilled() {
      return (
        !!this.editedNews.title?.replace(/ /g, "") &&
        !!this.editedNews.status &&
        !!this.editedNews.label?.replace(/ /g, "") &&
        !!this.editedNews.content?.replace(/ /g, "")
      );
    },
  },
  mounted() {
    this.fetchNews();
    this.fetchStatuses();
  },
  methods: {
    openCreationModal(data = this.defaultEditedNews, creation = true) {
      this.editedNews = {
        ...data,
        isNew: creation,
      };
      this.showCreationModal = true;
    },
    async fetchStatuses() {
      this.statuses = await NewsService.fetchStatuses();
    },
    async fetchNews() {
      this.news = await NewsService.fetchNews();
    },
    async createNews() {
      await NewsService.createNews(this.editedNews);
      this.showCreationModal = false;
      await this.fetchNews();
      this.editedNews = this.defaultEditedNews;
    },
    cancelCreation() {
      this.showCreationModal = false;
      this.editedNews = this.defaultEditedNews;
    },
    async updateNews() {
      await NewsService.updateNews(this.editedNews.id, this.editedNews);
      this.editedNews = this.defaultEditedNews;
      this.showCreationModal = false;
      await this.fetchNews();
    },
    async deleteNews(news) {
      if (await confirm(this.$t("news_delete", { news: news.title }))) {
        await NewsService.deleteNews(news.id);
        this.news = this.news.filter((row) => row.id !== news.id);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.content-editor {
  .ck-editor__editable {
    height: 150px;
    overflow: scroll;
  }
}
</style>
